body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(47, 154, 241);
  font-family: 'Roboto', sans-serif;
  transition: all 1s ease;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.center {
  display: flex;
  justify-content: center;
}

.link {
  text-decoration: none;
  color:rgb(71, 118, 161);
}

.pt-15px {
  padding-top: 15px;
}

.pt-17px {
  padding-top: 17px;
}

.pb-15px {
  padding-bottom: 15px;
}

.pl-5px {
  padding-left: 5px;
}

.pl-7px {
  padding-left: 7px;
}

.pl-20px {
  padding-left: 20px;
}

.pr-5px {
  padding-right: 5px;
}


.plain-text {
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
}

.ml-20px {
  margin-left: 20px;
}

.mr-10px {
  margin-right: 10px;
}

.mr-20px {
  margin-right: 20px;
}

.mt-15px {
  margin-top: 15px;
}

.mb-3px {
  margin-bottom: 3px;
}

.mb-8px {
  margin-bottom: 8px;
}

.flex-align-items-center {
  display: flex;
  align-items: center;
}

.logo-login {
  object-fit: cover;
  width: 100%;
  margin-top: 120px;
}

.menu-logo {
  object-fit: cover;
  width: 87%;
  padding-left: 16px;
}

.display-flex-0 {
  display: flex;
}

.display-flex {
  display: flex;
  justify-content: space-between;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.flex-space-evenly {
  display: flex;
  justify-content: space-evenly;
}


.flex-end {
  display: flex;
  justify-content: flex-end;
}

.color-black {
  color: rgb(41, 41, 41);
}

.color-white {
  color: white;
}

.font-size-17px {
  font-size: 17px;
}

.font-size-18px {
  font-size: 18px;
}

.font-size-40px {
  font-size: 40px;
  color: rgb(63, 63, 63);
}

.full-width {
  width: 100%;
}

.max-height-30px {
  max-height: 30px !important;
}

.code-modal {
  min-width: 53px;
  max-width: 500px;
  min-height: 100px;
  max-height: 500px;
  overflow: auto;
}

.modal-title {
  display: inline;
  max-width: 400px !important;
}

.csv-modal {
  min-width: 53px;
  max-width: 1000px;
  min-height: 50px;
  max-height: 500px;
  overflow: auto;
}

.form-buttons {
  display: flex;
  margin-top: 16px;
}

.type-icon {
  font-size: 28px;
  color: rgb(88, 88, 88);
}

.font-size-20px {
  font-size: 18px;
}

.MuiTab-wrapper {
  min-width: 8px !important;
}

.MuiTab-root {
  min-width: 150px !important;
}

.MuiOutlinedInput-inputAdornedEnd {
  padding: 10px !important;
  max-width: 00px !important;
  min-width:  100px !important;
}

.MuiBadge-root {
  padding-right: 3px;
}

.progress-position {
  transform: translateY(12px);
}

.percentage {
  transform: translate(4px, -9px);
  background-color: rgb(225, 225, 225);
  max-width: 27px;
  max-height: 17px;
  border-radius: 3px 3px 3px 3px ;
}

.percentage-100 {
  transform: translate(4px, -9px);
  background-color: rgb(225, 225, 225);
  max-width: 33px;
  max-height: 17px;
  border-radius: 3px 3px 3px 3px ;
}

.spinner-search {
  margin-top: 5px;
  margin-left: 21px;
  margin-right: 5px;
}

.transition {
  transition: all .7s ease;
}

.pointer-none {
  cursor: default !important;
}

.active-processes {
  margin-top: 40px !important;
  align-items: center;
  text-align: center;
}

.search-datagraph {
  margin-bottom: 5px;
}

.MuiAutocomplete-inputRoot {
  max-height: 37px !important;
}

#search-company-datagraph {
  margin-top: -7px !important;
  max-width: 250px !important;
  padding-right: 0 !important;
}

.MuiAutocomplete-inputRoot {
  padding-right: 5px !important;
}

.Mui-selected {
  background-color: #bbdefb !important;
  border-radius: 7px 7px 0 0 !important;
}

.MuiAutocomplete-inputRoot {
  max-width: 430px;
}

div.search-container {
  width: 330px !important;
  max-width: 430px !important;
  max-height: 35px !important;
}

div.checkbox {
  min-width: 315px !important;
}

.MuiAutocomplete-hasPopupIcon {
  width: auto !important;
}

.buttonReload {
  margin-left: 10px !important;
}

h2.MuiTypography-h6 {
  margin-bottom: 0 !important;
}